import commen from "@/utils/Commen";
import Animation from "@/utils/Animation";

export class BasicsControl {
  id: string = commen.getId()
  type = "img"
  infoX = 0
  infoY = 0
  infoWidth = 100
  infoHeight = 100
  coRightPosition = 0
  coLeftPosition = 0
  coWidth = 100
  coHeight = 100
  animation: Animation = new Animation()

  constructor(type: string, infoWidth: number, infoHeight: number) {
    this.type = type;
    this.infoWidth = infoWidth;
    this.infoHeight = infoHeight;
  }

}
