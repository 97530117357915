import {BasicsControl} from "@/utils/BasicsControl";

export class ImageControl extends BasicsControl {
  infoWidth = 100
  infoHeight = 100
  private url = ""


  constructor(type: string, url: string, infoWidth: number, infoHeight: number,) {
    super(type, infoWidth, infoHeight);
    this.infoWidth = infoWidth;
    this.infoHeight = infoHeight;
    this.url = url;
  }


}
