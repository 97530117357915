











import {Component, Vue} from "vue-property-decorator";
import {Action, State} from 'vuex-class'
import bus from "@/bus"
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class MoveControl extends Vue {
  public size = 15

  @State("controlIndex") controlIndex
  @State("controlList") controlList
  @Action("setControlIndex") setControlIndex
  @Action("empty") empty
  @Action("setControlList") setControlList
  @Action("addControl") addControl

  // 置顶
  public inTop() {
    let index = this.controlIndex
    let data = null
    if (index === this.controlList.length - 1) {
      bus.$elementMessage("已经是最顶层", 'error')
      return
    }

    data = cloneDeep(this.controlList[index])
    this.controlList.splice(index, 1)
    this.controlList.push(data)
    let arr = cloneDeep(this.controlList)
    this.empty()
    arr.forEach(res => {
      res.infoX = res.coLeftPosition
      res.infoY = res.coRightPosition
      res.infoWidth = res.coWidth
      res.infoHeight = res.coHeight
    })
    this.setControlList(JSON.stringify(arr))
    this.setControlIndex(this.controlList.length - 1)
  }

  // 上一层
  public descend() {
    let data = null
    let index = this.controlIndex

    if (index === this.controlList.length - 1) {
      bus.$elementMessage("已经是最顶层", 'error')
      return
    }

    data = cloneDeep(this.controlList[index])

    this.controlList[index] = cloneDeep(this.controlList[index + 1])
    bus.$set(this.controlList, index, cloneDeep(this.controlList[index + 1]))
    bus.$set(this.controlList, index + 1, data)

    let arr = cloneDeep(this.controlList)
    this.empty()
    arr.forEach(res => {
      res.infoX = res.coLeftPosition
      res.infoY = res.coRightPosition
      res.infoWidth = res.coWidth
      res.infoHeight = res.coHeight
    })
    this.setControlList(JSON.stringify(arr))

    this.setControlIndex(index + 1)
  }

  // 下一层
  public ascend() {
    let data = null
    let index = this.controlIndex
    if (index === 0) {
      bus.$elementMessage("已经是最底层", 'error')
      return
    }

    data = cloneDeep(this.controlList[index])
    bus.$set(this.controlList, index, cloneDeep(this.controlList[index - 1]))
    bus.$set(this.controlList, index - 1, data)
    let arr = cloneDeep(this.controlList)
    this.empty()
    arr.forEach(res => {
      res.infoX = res.coLeftPosition
      res.infoY = res.coRightPosition
      res.infoWidth = res.coWidth
      res.infoHeight = res.coHeight
    })
    this.setControlList(JSON.stringify(arr))
    this.setControlIndex(index - 1)
  }

  // 置顶
  public inBottom() {
    let data = null
    let index = this.controlIndex
    if (index === 0) {
      bus.$elementMessage("已经是最顶层", 'error')
      return
    }

    data = cloneDeep(this.controlList[index])
    this.controlList.splice(index, 1)
    this.controlList.unshift(data)
    let arr = cloneDeep(this.controlList)
    this.empty()
    arr.forEach(res => {
      res.infoX = res.coLeftPosition
      res.infoY = res.coRightPosition
      res.infoWidth = res.coWidth
      res.infoHeight = res.coHeight
    })
    this.setControlList(JSON.stringify(arr))
    this.setControlIndex(this.controlIndex)
  }


}
