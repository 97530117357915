var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EditTemp display_flex"},[_c('div',{staticClass:"control"},[_c('div',{staticStyle:{"height":"60px"}}),_vm._l((_vm.ControlBtns),function(item){return _c('div',{key:item.id,staticClass:"h5-btn cursor",on:{"click":function($event){return _vm.showAddModal(item.type)}}},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('p',[_vm._v(_vm._s(item.text))])])})],2),_c('div',{staticClass:"editor vertical"},[_c('div',{staticClass:"editor-btns conter"},[_c('a-space',{attrs:{"size":_vm.size}},[_c('a-button',{attrs:{"type":"primary","id":"save"},on:{"click":_vm.onSave}},[_vm._v("保存")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.empty}},[_vm._v("清空")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onPreview}},[_vm._v("预览")])],1)],1),_c('div',{ref:"editorBody",staticClass:"editor-body"},[_c('div',{ref:"editorBodyContent",staticClass:"editor-body-content"},[_c('div',{ref:"imageTofile",staticClass:"editor-body-notepad",staticStyle:{"position":"relative"},style:({
          width: Math.ceil((_vm.$route.query.type === '1' ? '1920' : '1080') *_vm.zoom) + 'px',
          height: Math.ceil((_vm.$route.query.type === '1' ? '1080' : '1920') * _vm.zoom) + 'px'
        })},[_c('div',{staticStyle:{"transform-origin":"0 0"},style:({
              transform: ("scale(" + _vm.zoom + ")"),
              width: (_vm.$route.query.type === '1' ? '1920' : '1080') + 'px',
              height: (_vm.$route.query.type === '1' ? '1080' : '1920') + 'px',
            })},_vm._l((_vm.controlList),function(item,index){return _c('vdr',{key:index,attrs:{"x":item.infoX,"y":item.infoY,"w":item.infoWidth,"h":item.infoHeight,"index":index,"scaleRatio":_vm.zoom,"parent":true},on:{"resizestop":_vm.onResize,"dragstop":_vm.onDrag,"activated":function($event){return _vm.onActivated(index)}}},[(item.type === 'img')?_c('img',{staticClass:"control-img animate__animated control-item",class:item.animation.animationClassName,style:({
                       animationIterationCount:item.animation.animationNum,
                       animationDuration:item.animation.animationTime + 's',
                       animationDelay:item.animation.animationDelay,
                       textDecoration:item.decoration,
                   }),attrs:{"id":item.id,"src":item.url}}):_vm._e(),(item.type === 'font')?_c('div',{staticClass:"animate__animated control-item",class:item.animation.animationClassName,style:({
                       animationIterationCount:item.animation.animationNum,
                       animationDuration:item.animation.animationTime + 's',
                       animationDelay:item.animation.animationDelay,
                       textDecoration:item.decoration,
                       height: '100%','fontStyle':item.incline,
                       fontWeight:item.bold, 'fontSize':(item.fontSize) + 'px',
                       color: item.fontColor,
                       textAlign:item.alignment,
                       whiteSpace: 'pre',
                       letterSpacing: item.letterSpacing + 'px',
                       lineHeight: item.lineSpacing
                   }),attrs:{"id":item.id}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])}),1)])])])]),_c('div',{staticClass:"operation"},[_c('div',{staticClass:"operation-tabs"},[(_vm.controlType === 'img')?_c('ImgSetting',{on:{"showAnimation":_vm.showAnimation,"replaceImg":_vm.replaceImg}}):_vm._e(),(_vm.controlType === 'font')?_c('FontSetting',{attrs:{"controlIndex":_vm.controlIndex},on:{"showAnimation":_vm.showAnimation}}):_vm._e()],1),_c('div',{staticClass:"operation-animation",class:_vm.animationShwo ? 'show' :'heid',attrs:{"id":"animationShwo"}},[_c('div',{staticClass:"btn-close",on:{"click":_vm.showAnimation}},[_c('a-icon',{attrs:{"type":"right"}})],1),_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"进入动画"}},[_c('a-row',{staticClass:"entranceList",attrs:{"gutter":[16,20]}},_vm._l((_vm.Entrance),function(item,index){return _c('a-col',{key:index,staticClass:"gutter-row cursor",attrs:{"span":6},on:{"click":function($event){return _vm.addAnimation(item)}}},[_c('div',{staticClass:"gutter-box ",on:{"mouseenter":function($event){return _vm.IMouseover($event,item.className)},"mouseleave":function($event){return _vm.IMouseout($event,item.className)}}},[_c('span',{staticClass:"entranceList-icon animate__animated",style:({
                  'background-position': ((item.x) + " " + (item.y))
                })}),_c('p',{staticClass:"entranceList-name"},[_vm._v(_vm._s(item.text))])])])}),1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"强调特效"}},[_c('a-row',{staticClass:"entranceList",attrs:{"gutter":[16,20]}},_vm._l((_vm.Emphasize),function(item,index){return _c('a-col',{key:index,staticClass:"gutter-row cursor",attrs:{"span":6},on:{"click":function($event){return _vm.addAnimation(item)}}},[_c('div',{staticClass:"gutter-box",on:{"mouseenter":function($event){return _vm.IMouseover($event,item.className)},"mouseleave":function($event){return _vm.IMouseout($event,item.className)}}},[_c('span',{staticClass:"entranceList-icon animate__animated",style:({
                  'background-position': ((item.x) + " " + (item.y))
                })}),_c('p',{staticClass:"entranceList-name"},[_vm._v(_vm._s(item.text))])])])}),1)],1)],1)],1)]),_c('a-modal',{attrs:{"visible":_vm.modalVisible,"width":"1000px","title":"添加素材","wrapClassName":"material-modal"},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('div',{staticClass:"material-content"},[_c('div',{staticClass:"material-grouping"},[_c('ul',_vm._l((_vm.MaterialGrouping),function(item,index){return _c('li',{key:item.materialGroupId,staticClass:"cursor grouping-item",class:_vm.selectIndex === index ? 'grouping-activate':'',on:{"click":function($event){return _vm.seleteMaterial(index,item.materialGroupId)}}},[_c('img',{attrs:{"src":require('@/assets/images/icon/folder.png'),"alt":""}}),_vm._v(" "+_vm._s(item.groupName)+" ")])}),0)]),_c('div',{staticClass:"material-body"},[_c('a-row',{attrs:{"gutter":[16,16]}},_vm._l((_vm.MaterialList),function(item){return _c('a-col',{key:item.materialId,attrs:{"lg":6}},[_c('img',{staticClass:"Material-item",class:_vm.materialId === item.materialId ? 'sleleImg':'',attrs:{"src":item.materialUrl,"alt":""},on:{"click":function($event){return _vm.onSeleteMaterial(item.materialUrl,item.materialId)}}})])}),1)],1)])]),_c('a-modal',{attrs:{"visible":_vm.modalVisiblePreview,"width":"1008px","title":"预览","wrapClassName":"material-modal"},on:{"cancel":_vm.handleCancel,"ok":function($event){_vm.modalVisiblePreview=false}}},[_c('div',{staticClass:"preview-content"},[_c('div',{style:({
      width: Math.ceil((_vm.$route.query.type === '1' ? '1920' : '1080') *_vm.scaleRatio) + 'px',
      height: Math.ceil((_vm.$route.query.type === '1' ? '1080' : '1920') * _vm.scaleRatio) + 'px'
      })},[_c('div',{staticStyle:{"transform-origin":"0 0"},style:({
              transform: ("scale(" + _vm.scaleRatio + ")"),
              width: (_vm.$route.query.type === '1' ? '1920' : '1080') + 'px',
              height: (_vm.$route.query.type === '1' ? '1080' : '1920') + 'px',
            })},_vm._l((_vm.controlList),function(item,index){return _c('vdr',{key:index,attrs:{"x":item.infoX,"y":item.infoY,"draggable":false,"resizable":false,"scaleRatio":_vm.scaleRatio,"w":item.infoWidth,"h":item.infoHeight,"index":index,"parent":true}},[(item.type === 'img')?_c('img',{staticClass:"control-img animate__animated",class:item.animation.animationClassName,style:({
                   animationIterationCount:item.animation.animationNum,
                   animationDuration:item.animation.animationTime + 's',
                   animationDelay:item.animation.animationDelay,
                   textDecoration:item.decoration
                   }),attrs:{"id":item.id,"src":item.url}}):_vm._e(),(item.type === 'font')?_c('div',{staticClass:"animate__animated",class:item.animation.animationClassName,style:({
                      textDecoration:item.decoration,
                      height: '100%','fontStyle':item.incline,
                      fontWeight:item.bold, 'fontSize':item.fontSize + 'px',
                      color: item.fontColor,
                      textAlign:item.alignment,
                      whiteSpace: 'pre',
                      letterSpacing: item.letterSpacing + 'px',
                      lineHeight: item.lineSpacing
                   }),attrs:{"id":item.id}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])}),1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }