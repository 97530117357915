










































































































































































































import {Component, Vue} from "vue-property-decorator";
import {ControlBtns, Entrance, Emphasize} from "@/defineData/definition";
import FontSetting from "@/components/FontSetting.vue";
import ImgSetting from "@/components/ImgSetting.vue";
import {ImageControl} from "@/utils/ImageControl";
import {State, Action} from 'vuex-class'
import {FontControl} from "@/utils/FontControl";
import vdr from '@/components/vue-draggable-resizable-gorkys/components/vue-draggable-resizable.vue'
import bus from "@/bus";
import html2canvas from 'html2canvas'
import commen from "@/utils/Commen";

@Component({
  components: {
    FontSetting,
    ImgSetting,
    vdr
  }
})
export default class EditTemp extends Vue {
  public ControlBtns = ControlBtns
  public presetLine = [{type: 'h', site: -20}, {type: 'v', site: -20}]
  public visible = true
  public modalVisible = false
  public modalVisiblePreview = false
  public size = 10
  public selectIndex = 0
  public MaterialGrouping = []
  public MaterialList = []
  public seleteImg = ""
  public materialId = ""
  public animationShwo = false
  public Entrance = Entrance
  public Emphasize = Emphasize
  public zoom = 1
  public scaleRatio = 0.28125
  public ImgType = 'add'
  public result: File
  public pageSize = 20
  public pageNum = 1
  public totality = 0
  public groupId = 0


  @State('controlList') controlList
  @State('controlIndex') controlIndex
  @State('controlType') controlType
  @Action('setControlIndex') setControlIndex
  @Action('addControl') addControl
  @Action('empty') empty
  @Action('setControlList') setControlList
  @Action('setControlType') setControlType

  public async mounted() {
    await this.setEditorBodyContentSize()
    await this.getTempData()

  }

  // 获取素材
  public getMaterial(groupId) {
    this.groupId = groupId
    bus.$post('/material/getMaterialList', {
      "pageSize": this.pageSize,
      "pageNum": this.pageNum,
      groupId
    }).then(res => {
      this.MaterialList = res.data.list
      this.totality = res.data.totalNum
    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

  // 点击分组
  public seleteMaterial(index, id) {
    this.selectIndex = index
    this.getMaterial(id)
  }

  // 获取分组
  public getGrouping() {
    bus.$post('/material/getMaterialGroupList').then(res => {
      this.MaterialGrouping = res.data.groupList
      this.getMaterial((this.MaterialGrouping[0] as any).materialGroupId)
    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

  // 添加分组
  public addGrouping() {
    this.visible = true;
  }

  // 获取模板数据
  getTempData() {
    bus.$post("/template/getTemplateById", {
      materialId: this.$route.query.id
    }).then(res => {
      this.setControlList(res.data.material.templateParam)
    }).catch(err => {
      console.log(err);
      bus.$elementMessage(err, 'error')
    })
  }

  // 替换图片
  replaceImg() {
    this.ImgType = "replace"
    this.getGrouping()
    this.modalVisible = true

  }

  // 拉伸控件
  public onResize(left, top, width, height, index) {
    this.controlList[index].coWidth = width
    this.controlList[index].coHeight = height
    this.controlList[index].coLeftPosition = left
    this.controlList[index].coRightPosition = top
  }

  // 移动控件
  public onDrag(left, top, index) {
    this.controlList[index].coLeftPosition = left
    this.controlList[index].coRightPosition = top
  }

  // 点击控件
  public onActivated(index) {
    this.setControlType(this.controlList[index].type)
    this.setControlIndex(index)
  }

  // 设置元素大小
  public setEditorBodyContentSize() {
    const editorBody = this.$refs.editorBody as any

    this.$nextTick(() => {
      const w = editorBody.offsetWidth - 20
      const h = editorBody.offsetHeight - 20

      //分辨率比例
      let p = 1
      let sw = 0
      let sh = 0
      let p1 = 1
      if (this.$route.query.type === '1') {
        p = 1920 / 1080
        sw = 1920
        sh = 1080
        p1 = w / h
      } else {
        p = 1080 / 1920
        sw = 1080
        sh = 1920
        p1 = h / w
      }

      //屏幕比例


      if (this.$route.query.type === '1') {
        if (p > p1) {
          this.zoom = w / sw
        } else {
          this.zoom = h / sh
        }
      } else {
        this.zoom = h / sh
      }
    })
  }

  //显示Modal
  public showAddModal(type: string) {
    let fontControl
    switch (type) {
      case "img":
        this.ImgType = "add"
        this.getGrouping()
        this.modalVisible = true
        break
      case "font":
        fontControl = new FontControl("font", "请输入文本内容", 200, 30)
        this.addControl(fontControl)
        this.setControlType(type)
        break
    }
  }

  // 关闭
  public handleCancel() {
    this.modalVisible = false
    this.modalVisiblePreview = false
  }

  // 选择素材
  onSeleteMaterial(data: string, id: string) {
    this.seleteImg = data
    this.materialId = id
  }

  // 添加素材
  public handleOk() {
    if (this.ImgType === 'add') {
      const imageControl = new ImageControl("img", this.seleteImg, 100, 100)
      this.setControlType("img")
      this.addControl(imageControl)
    } else {
      this.controlList[this.controlIndex].url = this.seleteImg
    }
    this.modalVisible = false
  }

  // 弹出动画
  public showAnimation() {
    this.animationShwo = !this.animationShwo
  }

  // 鼠标移入
  public IMouseover(event, data: string) {
    event.target.children[0].classList.add(data);
  }

  // 鼠标移出
  public IMouseout(event, data: string) {
    event.target.children[0].classList.remove(data);
  }

  // 添加动画
  addAnimation(data) {
    this.controlList[this.controlIndex].animation.animationName = data.text
    this.controlList[this.controlIndex].animation.animationClassName = data.className
    this.animationShwo = !this.animationShwo
  }

  // 预览
  public onPreview() {
    this.controlList.forEach(res => {
      res.infoX = res.coLeftPosition
      res.infoY = res.coRightPosition
      res.infoWidth = res.coWidth
      res.infoHeight = res.coHeight
    })
    if (this.$route.query.type === '1') {
      this.scaleRatio = 0.5
    } else {
      this.scaleRatio = 540 / 1920
    }

    this.modalVisiblePreview = true
  }

  // 保存
  async onSave() {
    // $(".control-item").removeClass("animate__animated")
    await html2canvas((this.$refs as any).imageTofile, {
      backgroundColor: null,     // 解决生成的图片有白边
      useCORS: true
    }).then((canvas) => {
      let jietu = canvas.toDataURL("image/jpeg")
      this.result = commen.dataURLtoFile(jietu, this.$route.query.id + '.jpeg')
    }).catch(err => {
      console.warn(err)
    })

    const form = new FormData()
    form.append("templateParam", JSON.stringify(this.controlList))
    form.append("materialId", (this.$route.query as any).id)
    form.append("file", this.result)
    form.append("type", (this.$route.query as any).type)


    await bus.$post("/template/saveTemplate", form).then(res => {
      // $(".control-item").addClass("animate__animated")
      bus.$elementMessage(res.msg, 'success')
    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

}
