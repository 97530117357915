
















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Action, State} from 'vuex-class'
import {FontSizes} from "@/defineData/definition";
import MoveControl from "@/components/MoveControl.vue";

@Component({
  components: {MoveControl}
})
export default class FontSetting extends Vue {
  public FontSizes = FontSizes
  public alignmentActive = ""
  public blodActive = false
  public inclineActive = false
  public deleLineActive = false
  public buttonLine = false
  public color = "#333333"
  public lucy = "1"
  public lineSpacing = 1
  public letterSpacing = 0
  public defaultValue = 16
  public animationDelayList = ['1', '2', '3', '4', '5', '6', '7', "infinite"]

  @State("controlList") controlList
  @Action("deleAnimation") deleAnimation

  @Prop({type: Number}) controlIndex

  get textarea() {
    return this.controlList[this.controlIndex] && this.controlList[this.controlIndex].text
  }

  set textarea(val: string) {

    this.controlList[this.controlIndex].text = val
  }

  // 动画名字
  get animationName() {
    return this.controlList[this.controlIndex].animation.animationName
  }

  set animationName(data) {
    console.log(data);
  }

  // 动画时间
  get animationTime() {
    return this.controlList[this.controlIndex].animation.animationTime
  }

  set animationTime(data) {
    this.controlList[this.controlIndex].animation.animationTime = data
  }

  // 动画次数
  get animationNum() {
    return this.controlList[this.controlIndex].animation.animationNum
  }

  set animationNum(data) {
    this.controlList[this.controlIndex].animation.animationNum = data
  }

// 字间距
  onfontChange(e) {
    console.log(e);
    this.controlList[this.controlIndex].letterSpacing = e
  }

  // 延时时间
  get animationDelay() {
    return this.controlList[this.controlIndex].animation.animationDelay
  }

  set animationDelay(data) {
    this.controlList[this.controlIndex].animation.animationDelay = data
  }

  mounted() {
    this.getControlIndex(this.controlIndex)
  }

  // 行间距
  onlineChange(e) {
    this.controlList[this.controlIndex].lineSpacing = e
  }

  // 加粗
  public setBlod() {
    if (this.controlList[this.controlIndex].bold === "100") {
      this.controlList[this.controlIndex].bold = "700"
      this.blodActive = true
    } else {
      this.controlList[this.controlIndex].bold = "100"
      this.blodActive = false
    }
  }

  //底部
  public setButtonLine() {
    if (this.controlList[this.controlIndex].decoration === "" || this.controlList[this.controlIndex].decoration === "line-through") {
      this.controlList[this.controlIndex].decoration = "underline"
      this.buttonLine = true
      this.deleLineActive = false
    } else {
      this.controlList[this.controlIndex].decoration = ""
      this.buttonLine = false
    }
  }

  // 删除线
  public setDeleteLine() {
    if (this.controlList[this.controlIndex].decoration === "" || this.controlList[this.controlIndex].decoration === "underline") {
      this.controlList[this.controlIndex].decoration = "line-through"
      this.deleLineActive = true
      this.buttonLine = false
    } else {
      this.controlList[this.controlIndex].decoration = ""
      this.deleLineActive = false
    }
  }

  // 倾斜
  public setIncline() {
    if (this.controlList[this.controlIndex].incline === "initial") {
      this.controlList[this.controlIndex].incline = "oblique"
      this.inclineActive = true
    } else {
      this.controlList[this.controlIndex].incline = "initial"
      this.inclineActive = false
    }
  }

  // 对齐方式
  public setAlignment(data) {
    this.alignmentActive = data
    switch (data) {
      case "left":
        this.controlList[this.controlIndex].alignment = this.alignmentActive
        break
      case "center":
        this.controlList[this.controlIndex].alignment = this.alignmentActive
        break
      case "right":
        this.controlList[this.controlIndex].alignment = this.alignmentActive
        break
    }
  }

  // 文本颜色
  public headleChangeColor(data) {
    this.controlList[this.controlIndex].fontColor = data
  }

  // 添加文字
  public setText(data) {
    this.controlList[this.controlIndex].text = data
  }

  // 文字大小
  public handleChange(data) {
    this.controlList[this.controlIndex].fontSize = data
  }

  // 显示动画列表
  showAnimation() {
    this.$emit("showAnimation")
  }


  getControlIndex(new_val) {
    this.color = this.controlList[new_val].fontColor
    this.defaultValue = this.controlList[new_val].fontSize
    this.blodActive = this.controlList[new_val].bold === "700"
    this.inclineActive = this.controlList[new_val].incline === "oblique"
    this.deleLineActive = this.controlList[new_val].decoration === "line-through"
    this.buttonLine = this.controlList[new_val].decoration === "underline"
    this.alignmentActive = this.controlList[new_val].alignment
    this.lineSpacing = this.controlList[new_val].lineSpacing
    this.letterSpacing = this.controlList[new_val].letterSpacing
  }

  @Watch('controlIndex')
  getControlIndexs(index) {
    this.getControlIndex(index)
  }
}
