

















































































import {Component, Vue, Watch} from "vue-property-decorator";
import MoveControl from "@/components/MoveControl.vue";
import {Action, State} from 'vuex-class'

@Component({
  components: {
    MoveControl
  }
})
export default class ImgSetting extends Vue {
  public animationDelayList = ['1', '2', '3', '4', '5', '6', '7', "infinite"]

  @State("controlIndex") controlIndex
  @State("controlList") controlList
  @Action("deleAnimation") deleAnimation

  /*-------------属性---------------------*/
  get animationName() {
    return this.controlList[this.controlIndex].animation.animationName
  }

  get imgUrl() {
    return this.controlList[this.controlIndex].url
  }

  set imgUrl(data) {
    console.log(data);
  }

  get animationTime() {
    return this.controlList[this.controlIndex].animation.animationTime
  }

  set animationTime(data) {
    console.log(data);
  }

  get animationNum() {
    return this.controlList[this.controlIndex].animation.animationNum
  }

  set animationNum(data) {
    console.log(data);
  }

  get animationDelay() {
    return this.controlList[this.controlIndex].animation.animationDelay
  }

  set animationDelay(data) {
    console.log(data);
  }

  //替换图片
  replaceImg() {
    this.$emit('replaceImg')
  }

  // 选择动画次数
  setAnimationNum(data) {
    this.controlList[this.controlIndex].animation.animationNum = data
  }

  // 设置动画时间
  setAnimationTime(data) {
    this.controlList[this.controlIndex].animation.animationTime = data
  }

  // 设置动画延时时间
  setAnimationDelay(data) {
    this.controlList[this.controlIndex].animation.animationDelay = data
  }

  // 显示动画列表
  showAnimation() {
    this.$emit("showAnimation")
  }


}
