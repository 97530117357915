
export const ControlBtns = [
  {
    id: "1",
    text: "图片",
    url: require("@/assets/images/icon/img.png"),
    type: "img"
  }, {
    id: "2",
    text: "文字",
    url: require("@/assets/images/icon/font.png"),
    type: "font"
  }
]

// 文字大小
export const FontSizes = [
  14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36
]

// 动画类型
export const Entrance = [
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "node"
  },
  {
    text: "快闪",
    x: "0",
    y: "-360px",
    className: "animate__flash"
  },
  {
    text: "上滑入",
    x: "",
    y: "",
    className: "animate__slideInDown"
  },
  {
    text: "上渐入",
    x: "",
    y: "",
    className: "animate__fadeInDown"
  },
  {
    text: "上长渐入",
    x: "",
    y: "",
    className: "animate__fadeInDownBig"
  },
  {
    text: "下滑入",
    x: "",
    y: "",
    className: "animate__slideInUp"
  },
  {
    text: "下渐入",
    x: "",
    y: "",
    className: "animate__fadeInUp"
  },
  {
    text: "下长渐入",
    x: "",
    y: "",
    className: "animate__fadeInUpBig"
  },
  {
    text: "左滑入",
    x: "",
    y: "",
    className: "animate__slideInLeft"
  },
  {
    text: "左渐入",
    x: "",
    y: "",
    className: "animate__fadeInLeft"
  },
  {
    text: "左长渐入",
    x: "",
    y: "",
    className: "animate__fadeInLeftBig"
  },
  {
    text: "左滑入",
    x: "",
    y: "",
    className: "animate__slideInRight"
  },
  {
    text: "左渐入",
    x: "",
    y: "",
    className: "animate__fadeInRight"
  },
  {
    text: "左长渐入",
    x: "",
    y: "",
    className: "animate__fadeInRightBig"
  },
  {
    text: "左上滚入",
    x: "",
    y: "",
    className: "animate__rotateInDownLeft"
  },
  {
    text: "右上滚入",
    x: "",
    y: "",
    className: "animate__rotateInDownRight"
  },
  {
    text: "右下滚入",
    x: "",
    y: "",
    className: "animate__rotateInUpLeft"
  },
  {
    text: "左下滚入",
    x: "",
    y: "",
    className: "animate__rotateInUpRight"
  },
  {
    text: "从上放大",
    x: "",
    y: "",
    className: "animate__zoomInDown"
  },
  {
    text: "从下放大",
    x: "",
    y: "",
    className: "animate__zoomInUp"
  },
  {
    text: "从左放大",
    x: "",
    y: "",
    className: "animate__zoomInLeft"
  },
  {
    text: "从右放大",
    x: "",
    y: "",
    className: "animate__zoomInRight"
  },
]

// 渐现
export const Emphasize = [
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__bounceIn"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__flash"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__pulse"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__rubberBand"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__shakeX"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__shakeY"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__headShake"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__swing"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__tada"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__wobble"
  },
  {
    text: "无动画",
    x: "0",
    y: "-360px",
    className: "animate__heartBeat"
  }
]
