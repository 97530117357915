import {BasicsControl} from "@/utils/BasicsControl";

export class FontControl extends BasicsControl {
  private text = "" // 内容
  private fontSize = 16 // 字体大小
  private alignment = "start" // 对齐方式
  private bold = "100" // 加粗
  private incline = "initial" // 倾斜
  private decoration = "" // 下划线
  private fontColor = "#333333" // 字体颜色
  private lineSpacing = 1 // 行间距
  private letterSpacing = 0 // 字体间距

  constructor(type: string, text: string, infoWidth: number, infoHeight: number) {
    super(type, infoWidth, infoHeight);
    this.text = text;
  }

}
